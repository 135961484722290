import { DocUploadSVG } from "assets/fileUpload/DocUploadSVG";
import { useState } from "react";

import { JpgFile } from "assets/fileUpload/JpgFile";
import { PdfFile } from "assets/fileUpload/PdfFile";
import { PngFile } from "assets/fileUpload/PngFile";

import { CircleCancel } from "assets/customButtons/CircleCancel";
import { Button } from "components/button/Button";

import * as API from "apis/api";

import { Loading } from "assets/loading/Loading";
import { SimpleCheckSVG } from "assets/usecase/SimpleCheckSVG";

import { AnalyticsBrowser } from "@june-so/analytics-next";
import { useNotifications } from "components/notificationSystem/notificationContext";
import { JuneEvents, trackJune } from "helpers/hooks/useJune";
import { PDFDocument } from "pdf-lib";

type Props = {
  useCaseID: string;
  afterUploadCallback: any;
  analytics: AnalyticsBrowser | null;
};

export const FileUploader: React.FC<Props> = ({
  useCaseID,
  afterUploadCallback,
  analytics,
}) => {
  const maxPDFPages = 15;

  const [filesToUpload, setFilesToUpload] = useState<
    Array<{ status: String; file: File }>
  >([]);

  const filterPDF = async (fileList: File[]) => {
    const result = [];

    for (const file of fileList) {
      if (file.type !== "application/pdf") {
        result.push(file);
        continue;
      }
      const arrBuffer = await file.arrayBuffer();
      const byteArray = new Uint8Array(arrBuffer);
      const pdfDoc = await PDFDocument.load(byteArray, {
        ignoreEncryption: true,
      });
      const length = pdfDoc.getPages().length;

      if (length <= maxPDFPages) result.push(file);
      else {
        document.getElementById("max-pages-allowed")!.style.color = "red";
        document.getElementById("max-pages-allowed")!.style.fontWeight = "500";
        document.getElementById("max-pages-allowed")!.style.animation =
          "error-pulse 0.5s ease-in-out";
      }
    }

    return result;
  };

  const handleSetFilesToUpload = async (files: FileList) => {
    let allowedFile = Array.from(files).filter((x) => x.size <= 4194304);

    let pdfFilteredFileList = await filterPDF(allowedFile);

    let allowedFileList = pdfFilteredFileList.map((x) => {
      return { status: "wait", file: x };
    });
    if (Array.from(files).filter((x) => x.size >= 4194304).length > 0) {
      document.getElementById("max-memory-allowed")!.style.color = "red";
      document.getElementById("max-memory-allowed")!.style.fontWeight = "500";
      document.getElementById("max-memory-allowed")!.style.animation =
        "error-pulse 0.5s ease-in-out";
    }
    !filesToUpload
      ? setFilesToUpload(allowedFileList)
      : setFilesToUpload((old) =>
          old?.concat(allowedFileList).filter((x) => x.status !== "uploaded")
        );
  };

  const [uploading, setUploading] = useState<boolean>(false);

  const icons = new Map([
    ["image/png", <PngFile width={35} height={35} />],
    ["image/jpeg", <JpgFile width={35} height={35} />],
    ["image/jpg", <JpgFile width={35} height={35} />],
    ["application/pdf", <PdfFile width={35} height={35} />],
  ]);

  const handleAfterUploadRendering = () => {
    setUploading(false);
    let container = document.getElementById("overlay-loader")!;
    container.style.animation = `closeLoader 1s ease`;

    container.addEventListener("animationend", () => {
      container.style.animation = "";
      container.style.opacity = "0";
      closePanel();
      container.style.opacity = "1";
    });

    afterUploadCallback();
  };

  const handleFilesUpload = async () => {
    setUploading(true);
    let realFiles = filesToUpload.filter((x) => icons.has(x.file.type));
    realFiles.forEach((x) => (x.status = "uploading"));
    setFilesToUpload(realFiles);
    recursiveUpload(realFiles, 0);
  };

  const notification = useNotifications();

  const recursiveUpload = async (
    realFiles: Array<{ status: String; file: File }>,
    index: number
  ) => {
    await API.addDocument(useCaseID, realFiles[index].file)
      .then((res) => {
        realFiles[index].status = "uploaded";
        trackJune(analytics, JuneEvents.USED);
        if (realFiles.length === index + 1) {
          handleAfterUploadRendering();
        } else recursiveUpload(realFiles, index + 1);
      })
      .catch((e) => {
        notification.addNotification({
          title: "File upload error",
          message: "File upload error",
          severity: "ERROR",
          timeout: 3000,
        });
        if (realFiles.length === index + 1) {
          handleAfterUploadRendering();
        } else recursiveUpload(realFiles, index + 1);
      });
  };

  const closePanel = () => {
    setFilesToUpload([]);
    document.querySelector<HTMLElement>(".overlay-loader")!.style.display =
      "none";
    document.getElementById("max-memory-allowed")!.style.color = "black";
    document.getElementById("max-memory-allowed")!.style.fontWeight = "100";
    document.getElementById("max-memory-allowed")!.style.animation = "";
    document.getElementById("max-pages-allowed")!.style.color = "black";
    document.getElementById("max-pages-allowed")!.style.fontWeight = "100";
    document.getElementById("max-pages-allowed")!.style.animation = "";
  };

  return (
    <div id="loader-container" className="demo-loader-container">
      <button
        id="loader-reset-btn"
        className="absolute -top-10 -left-10 bg-blue-500"
        onClick={(e) => {
          closePanel();
        }}
      />
      <input
        type="file"
        id="file-input"
        className="hidden"
        multiple
        onChange={(e) => {
          document.getElementById("max-memory-allowed")!.style.color = "black";
          document.getElementById("max-memory-allowed")!.style.fontWeight =
            "100";
          document.getElementById("max-memory-allowed")!.style.animation = "";
          document.getElementById("max-pages-allowed")!.style.color = "black";
          document.getElementById("max-pages-allowed")!.style.fontWeight =
            "100";
          document.getElementById("max-pages-allowed")!.style.animation = "";
          e.target.files && handleSetFilesToUpload(e.target.files);
        }}
        accept=".png,.jpg,.jpeg,.pdf"
      />
      <div className="bg-white p-5 w-[48rem] h-fit rounded-myb flex flex-col gap-5 !transition-all !duration-1000 !ease-in-out">
        <div className="relative w-full h-fit flex flex-col gap-1">
          <h1 className="text-2xl font-bold text-primary-color">
            Upload your documents
          </h1>
          <span className="font-thin text-slate-500">
            Upload your documents and start processing!
          </span>
        </div>
        <div
          className="relative bg-[rgb(244,247,252)] w-full h-64 border rounded-myb flex items-center justify-center"
          onDragOver={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            handleSetFilesToUpload(e.dataTransfer.files);
          }}
        >
          <div className="relative bg-transparent h-fit w-fit flex flex-col items-center gap-2">
            <DocUploadSVG width={60} height={60} />
            <span className="font-semibold">
              Drag & Drop or{" "}
              <button
                className=" text-blue-700 underline"
                onClick={() => {
                  document.getElementById("file-input")?.click();
                }}
              >
                {" "}
                Choose file
              </button>{" "}
              here
            </span>
          </div>
          <div className="absolute w-fit h-fit -bottom-10 right-0 flex flex-col items-end">
            <span
              id="max-memory-allowed"
              className="font-light text-sm text-slate-500 !blur-0"
            >
              {" "}
              max size: 4MB, supported formats: jpeg/jpg, png, pdf.{" "}
            </span>
            <span
              id="max-pages-allowed"
              className="font-light text-sm text-slate-500 !blur-0"
            >
              {" "}
              Pdf must have max 15 pages{" "}
            </span>
          </div>
        </div>

        <div className="relative flex flex-col w-full !min-h-0 max-h-96 mt-5  gap-2 overflow-y-auto !transition-all !duration-1000 !ease-in-out">
          {filesToUpload.map(({ status, file }) => (
            <div className="h-16 min-h-[4rem] border rounded-myb flex flex-row items-center px-5 gap-5">
              <div className="relative w-10">{icons.get(file.type)}</div>
              <div className=" w-[calc(100%-120px)] flex flex-col">
                <h2 className="font-semibold">{file.name}</h2>
                <span className="font-thin">
                  {(file.size / 1024 / 1024).toFixed(2)} MB
                </span>
              </div>
              <div className="h-10 w-10 flex items-center justify-end">
                {status === "uploading" ? (
                  <Loading />
                ) : status === "uploaded" ? (
                  <SimpleCheckSVG width={25} height={25} stroke="green" />
                ) : (
                  <button
                    onClick={() => {
                      setFilesToUpload((old) =>
                        old.filter((oldFile) => oldFile.file !== file)
                      );
                    }}
                  >
                    <CircleCancel width={25} height={25} />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        {filesToUpload.length > 0 && (
          <div className="h-10 w-full flex flex-row justify-end">
            <Button
              inverted
              action={() => {
                handleFilesUpload();
              }}
              disabled={uploading}
            >
              {" "}
              Upload{" "}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
