import "rc-slider/assets/index.css";
import { useContext } from "react";
import {
  MdOutlineRotate90DegreesCw,
  MdZoomIn,
  MdZoomOut,
} from "react-icons/md";

import { StageContext } from "routes/Document Result/stageContext";
type Props = {
  zoom: (scale: number) => void;
  rotate: (angle: number) => void;
};

export function CanvasToolbar({ zoom, rotate }: Props) {
  const minZoom = 0.1;
  const maxZoom = 1.0;
  const zoomModifier = 1.5;
  const zoomStep = 0.1;
  const stageContext = useContext(StageContext);
  const zoomValue = stageContext?.zoomValue;
  const setZoomValue = stageContext?.setZoomValue;

  if (!stageContext || !zoomValue || !setZoomValue) return <></>;
  return (
    <div className="absolute z-30 !w-full flex justify-center h-fit !pointer-events-none ">
      <div
        onMouseEnter={(e) => {
          document.getElementById("toolbar")!.style.opacity = "1";
        }}
        onMouseLeave={(e) => {
          let relatedTarget = e.relatedTarget as HTMLElement;
          if (
            relatedTarget!.id === "toolbar-item" ||
            relatedTarget!.id === "canvas"
          )
            return;
          document.getElementById("toolbar")!.style.opacity = "0.2";
        }}
        id="toolbar"
        className="w-fit shadow-md h-10 bg-white rounded-b-myb grid grid-flow-col !pointer-events-auto !transition-all !duration-300 !ease-out"
      >
        <button
          id="toolbar-item"
          onClick={() => {
            rotate(90);
          }}
          className="flex items-center justify-center w-10 h-full"
        >
          <MdOutlineRotate90DegreesCw size={20} />
        </button>
        <div
          id="toolbar-item"
          className="box-border self-stretch my-auto !w-[1px] !h-[20.1px] bg-[hsl(0,0%,80%)]"
        ></div>
        <div
          id="toolbar-item"
          className="w-fit flex flex-row gap-2 items-center h-full px-2"
        >
          <button
            name="toolbar-item"
            disabled={zoomValue <= minZoom}
            onClick={() => {
              if (zoomValue - zoomStep < minZoom) {
                zoom(minZoom * zoomModifier);
                setZoomValue(minZoom);
              } else {
                zoom((zoomValue - zoomStep) * zoomModifier);
                setZoomValue((old: number) => old - zoomStep);
              }
            }}
          >
            <MdZoomOut size={22} />
          </button>
          {
            // This is the slider that controls the zoom
            /* <div className="w-36">
            <Slider
              value={zoomValue}
              min={0.1}
              max={1}
              step={0.01}
              railStyle={{
                backgroundColor: "white",
                border: "1px solid #EAEBFB",
                borderRadius: "2px",
                height: "10px",
                top: "2px",
              }}
              handleStyle={{ backgroundColor: "#1a3066", opacity: "10" }}
              trackStyle={{ backgroundColor: "transparent" }}
              dotStyle={{
                border: "1px solid #1a3066",
                width: "1px",
                borderRadius: "1px",
                opacity: "0.4",
              }}
              onChange={(e) => {
                zoom(Number(e) * 1.5);
                setZoomValue(Number(e));
              }}
            />
          </div>
          */
          }
          <div
            id="toolbar-item"
            className="box-border self-stretch my-auto !w-[1px] !h-[20.1px] bg-[hsl(0,0%,80%)]"
          ></div>
          <button
            disabled={zoomValue >= maxZoom}
            onClick={() => {
              if (zoomValue + zoomStep > maxZoom) {
                zoom(maxZoom * zoomModifier);
                setZoomValue(maxZoom);
              } else {
                zoom((zoomValue + zoomStep) * zoomModifier);
                setZoomValue((old: number) => old + zoomStep);
              }
            }}
          >
            <MdZoomIn size={22} />
          </button>
        </div>
      </div>
    </div>
  );
}
