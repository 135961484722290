import { Dict } from "routes/Document Result/components/aggregatedAnnotationPanel";

export const modelsEntityOrder: Dict<Array<string>> = {
  model1: [
    "name_1",
    "surname_1",
    "id_number_1",
    "issue_date_1",
    "expire_date_1",
    "birth_date_1",
    "birth_place_1",
    "issue_place_1",
    "residence_1",
    "address_1",
    "nationality_1",
    "fiscal_code_1",
    "sex_1",
  ],
  model2: [
    "issue_date_1",
    "time_1",
    "total_1",
    "currency_1",
    "terms_of_payment_1",
    "vendor_name_1",
    "vat_1",
    "phone_number_1",
    "address_1",
    "residence_1",
    "postal_code_1",
  ],
  model13: [
    "code_1",
    "date_1",
    "company_name_1",
    "vat_1",
    "address_1",
    "residence_1",
    "postal_code_1",
    "place_1",
    "total_1",
    "id_number_3",
    "id_number_2",
    "id_number_5",
  ],
  model14: [
    "id_number_1",
    "date_1",
    "id_number_2",
    "date_2",
    "company_name_1",
    "address_1",
    "postal_code_1",
    "residence_1",
    "country_1",
    "vat_3",
    "id_number_3",
    "id_number_15",
    "company_name_2",
    "address_2",
    "postal_code_2",
    "residence_2",
    "fiscal_code_1",
    "vat_1",
    "company_name_3",
    "address_3",
    "postal_code_3",
    "residence_3",
    "fiscal_code_2",
    "vat_2",
    "id_number_4",
    "id_number_5",
    "id_number_6",
    "id_number_7",
    "date_3",
    "date_4",
    "id_number_8",
    "address_4",
    "address_5",
    "name_1",
    "id_number_9",
    "id_number_10",
    "id_number_11",
    "id_number_12",
    "id_number_13",
    "id_number_14",
    "id_number_17",
    "id_number_16",
    "id_number_18",
  ],
};

/*
    "code_2": {
      "tag": "code",
      "tag_alias": "Item Code",
      "tag_multiple_values": true,
      "tag_aggregation": null
    },
    "id_number_1": {
      "tag": "id_number",
      "tag_alias": "Item Quantity",
      "tag_multiple_values": true,
      "tag_aggregation": null
    },
    "numeric_1": {
      "tag": "numeric",
      "tag_alias": "Item Price",
      "tag_multiple_values": true,
      "tag_aggregation": null
    },
    "line_item_1": {
      "tag": "line_item",
      "tag_alias": "Line Item",
      "tag_multiple_values": true,
      "tag_aggregation": {
        "aggregation_type": "geometric_clustering",
        "aggregation_fields": [
          "code_2",
          "id_number_1",
          "numeric_1"
        ]
      }
    }
*/
