import { useEffect, useRef, useState } from "react";
import * as API from "../../apis/api";
import Plan from "../../components/plan/plan";

import { Button } from "components/button/Button";
import { getModelInfo, modelInfo } from "../../components/products/products";
/* FREE TRIAL CONSTANTS*/

const ulSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke={"#389ee1"}
    className="w-6 h-6 mr-1"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    />
  </svg>
);

type PlanDict = {
  [key: string]: { use_cases: Array<string> };
};

type OrgPlanDict = {
  free_plan_count: number;
  free_plans: PlanDict;
  premium_plan_count: number;
  premium_plans: PlanDict;
};

/* STUBBED PLAN LIMIT */
const planLimit = 3;

export const PlansRoute: React.FC<void> = () => {
  const [orgPlansUsages, setOrgPlansUsages] = useState<null | Array<any>>(null);
  const [orgFreePlanCount, setOrgFreePlanCount] = useState<number>(0);
  const planListRef = useRef<Array<any>>([]);

  useEffect(() => {
    const fetchOrganizationPlans = async () => await API.getOrganizationPlans();
    fetchOrganizationPlans().then(async (plans: OrgPlanDict) => {
      // eslint-disable-next-line no-unused-vars
      setOrgFreePlanCount(plans.free_plan_count);
      let ucList: any = [];
      for (const [_, plan] of Object.entries(plans["premium_plans"])) {
        let plan_ucList = plan["use_cases"];

        for (const uc of plan_ucList) {
          await API.getMonitorUseCasePlan(uc).then(async (uc_plan) => {
            const use_case = await API.getUseCase(uc);

            let newUCPlan = {
              use_case: uc,
              use_case_name: use_case.name,
              model: use_case.model,
              type: "premium",
              usage: uc_plan.plan_usage["usage"],
              usage_limit: uc_plan.plan_usage["usage_limit"],
            };

            ucList = [...ucList, newUCPlan];
          });
        }
      }

      // eslint-disable-next-line no-unused-vars
      for (const [_, plan] of Object.entries(plans["free_plans"])) {
        let plan_ucList = plan["use_cases"];

        for (const uc of plan_ucList) {
          await API.getMonitorUseCasePlan(uc).then(async (uc_plan) => {
            const use_case = await API.getUseCase(uc);

            let newPlan = {
              use_case: uc,
              use_case_name: use_case.name,
              model: use_case.model,
              type: "free",
              usage: uc_plan.plan_usage["usage"],
              usage_limit: uc_plan.plan_usage["usage_limit"],
            };

            ucList = [...ucList, newPlan];
          });
        }
      }
      setOrgPlansUsages(() => ucList);
    });
  }, []);

  return (
    <div className="product-bg default-page-layout max-lg:!overflow-y-scroll">
      <div className="default-page-container max-[950px]:!max-w-[800px]">
        <div className="dashboard-head">
          <div className="page-header">
            <div className="text-page-title">Your Plan</div>
          </div>
        </div>
        <div className="dashboard-body">
          <div className="plan-container">
            <div
              className={
                "w-full grid max-lg:grid-rows-[300px_420px] max-lg:gap-y-4 lg:grid-cols-[33%_66%] gap-x-5 px-2 py-3"
              }
            >
              <div className="w-full">
                <div id="recap_plan" className="plan-card max-lg:!h-[300px]">
                  <div className={"plan-card-header"}>
                    <div>
                      <h3 className="text-normal font-semibold">Plan Info</h3>
                    </div>
                  </div>
                  <div className={"plan-card-body text-sm"}>
                    <span className={"mb-2 text-subtitle"}>
                      Your myBiros free plan includes:
                    </span>
                    <ul className={"ml-2 text-description"}>
                      <li className={"mt-3 mb-2"}>
                        <div className={"flex flex-row gap-2"}>
                          {ulSVG}
                          <span>A trial of our prebuilt services</span>
                        </div>
                      </li>
                      <li className={"mb-2"}>
                        <div className={"flex flex-row gap-2"}>
                          {ulSVG}
                          <span>First 3 free use cases</span>
                        </div>
                      </li>
                      <li className={"mb-2"}>
                        <div className={"flex flex-row gap-2"}>
                          {ulSVG}
                          <span>First 300 free pages</span>
                        </div>
                      </li>
                      <li className={"mb-2"}>
                        <div className={"flex flex-row gap-2"}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke={"#389ee1"}
                            className="w-6 h-6 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                          <span>No credit card required</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="plan-card !w-full !max-w-full">
                  <div
                    className={"plan-card-header flex flex-row justify-between"}
                  >
                    <div>
                      <h3 className="text-normal font-semibold">
                        Plan Use Cases Usage
                      </h3>
                    </div>
                    {orgPlansUsages && (
                      <div className={"text-subtitle-small"}>
                        {orgFreePlanCount === planLimit ? (
                          <span className={"bg-red-200 w-60 p-2 rounded-myb"}>
                            You have exhausted your free plan's use cases
                          </span>
                        ) : (
                          <span
                            className={
                              "bg-green-100 text-green-800 w-60 p-2 rounded-myb"
                            }
                          >
                            You currently have {planLimit - orgFreePlanCount}{" "}
                            use cases available in your plan
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="relative flex flex-col gap-5 overflow-y-auto">
                    {orgPlansUsages &&
                      orgPlansUsages.map((plan) => (
                        <div className="w-full h-36 mb-2 p-3 rounded-myb shadow">
                          <div className="bg-white relative h-full w-full px-3 flex flex-row justify-start items-center  ">
                            <div className="w-20 mx-2 mr-5">
                              {
                                getModelInfo(
                                  plan.model as keyof typeof modelInfo
                                ).bigSVG
                              }
                            </div>
                            <div className="flex flex-col gap-5 pb-1 w-full">
                              <div className="flex gap-2 w-full">
                                <span className="text-md w-fit">
                                  {plan.use_case_name}
                                </span>
                                <div
                                  className="revision-status-pill !w-fit px-2"
                                  style={{
                                    borderColor: "rgba(0,255,220,0.85)",
                                    backgroundColor: "rgb(0,255,220,0.25)",
                                  }}
                                >
                                    {plan.type.charAt(0).toUpperCase() + plan.type.slice(1)}
                                </div>
                              </div>
                              <div className="-mt-5">
                                <Plan
                                  text={"Usage"}
                                  planUsage={plan.usage}
                                  planLimit={plan.usage_limit}
                                  width={30}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className={"w-3/3"}>
              <div className="plan-cta-box">
                <h2 className="text-normal font-semibold text-primary-color">
                  Need Plan Extension?
                </h2>
                <div className="flex flex-row gap-2">
                  <a
                    href={"https://mybiros.com#form"}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button className="outline-1 flex flex-row gap-2 text-[18px]">
                      Request Pro Plan
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
