
import ImageFromUrl from "helpers/imageFromUrl";
import { useContext, useEffect, useRef, useState } from "react";
import { Layer, Line, Rect, Stage, Transformer } from "react-konva";
import { SvgLinePortal } from "routes/Document Result/components/svgLinePortal";
import { StageContext } from "routes/Document Result/stageContext";
import stc from "string-to-color";
import { CanvasToolbar } from "./components/canvasToolbar";
export default function ProductResultCanvas({ imageAttrs, setStageSize, stageSize, inferenceResult, fileURL, handleAddNewAnnotation, editSimpleAnnotation, handleEditAnnotation, defaultAddLabel, setAddSimpleAnnotationMode, addSimpleAnnotationMode, OCR }) {


    const { stageRef, annotationRect, setAnnotationRect, highlighted, setSelectedText, zoomValue } = useContext(StageContext)

    const [result, setResult] = useState(null)

    const imageLayerRef = useRef();

    const bbLayerRef = useRef();

    useEffect(() => {
        if (defaultAddLabel === '') return;
        setNewAnnotationRect(null);
        setAnnotationRect(null);
        setAddSimpleAnnotationMode(true)
    }, [defaultAddLabel])

    useEffect(() => {
        if (addSimpleAnnotationMode) return;
        setNewAnnotationRect(null);
        setAnnotationRect(null);
        setAddSimpleAnnotationMode(false)
    }, [addSimpleAnnotationMode])

    useEffect(() => {
        if (!inferenceResult) return;

        let tempArray = [];
        Object.entries(inferenceResult).forEach((entry, index) => {
            let [key, annotation] = entry
            if (Array.isArray(annotation)) {
                if (annotation.length === 0)
                    tempArray.push({ id: annotation.id, label: key, BoundingBox: null })
                else {
                    annotation.forEach((single) => {
                        if (single['bounding_boxes'])
                            single['bounding_boxes'].forEach((singleBB, _) => {
                                tempArray.push({ id: single.id, label: key, BoundingBox: singleBB.flat() })
                            })


                        else
                            tempArray.push({ id: single.id, label: key, BoundingBox: null })
                    })
                }
                return
            }

            else if (annotation['bounding_boxes'])
                annotation['bounding_boxes'].forEach((singleBB, _) => {
                    tempArray.push({ id: annotation.id, label: key, BoundingBox: singleBB.flat() })
                })


            else
                tempArray.push({ id: annotation.id, label: key, BoundingBox: null })
        })

        setResult(tempArray)


    }, [inferenceResult])



    function zoom(scaleBy) {
        let stage = stageRef.current;
        stage.scale({ x: scaleBy, y: scaleBy })
        stage.batchDraw();
    }



    const resetLine = () => {
        let svgLine = document.getElementById('line')
        let svgCircle = document.getElementById('circle')
        svgLine.x1.baseVal.value = 0
        svgLine.y1.baseVal.value = 0
        svgLine.x2.baseVal.value = 0
        svgLine.y2.baseVal.value = 0

        svgCircle.cx.baseVal.value = 0
        svgCircle.cy.baseVal.value = 0
        svgCircle.r.baseVal.value = 0
    }



    useEffect(() => {
        /*
        let svgColor = 'black' //#1a3066
        let svgLine = document.getElementById('line')
        let svgCircle = document.getElementById('circle')
        let stageContainer = document.getElementById('stage-container').children[0]

        let targetBB = bbLayerRef.current.findOne(`#bb-${highlighted}`)
        let fromPill = document.getElementById(`anchor-${highlighted}`)
        if (fromPill) {
            svgColor = fromPill.style.backgroundColor
        }
        let galleryDiv = document.getElementById(`gallery`)
        let sidebarDiv = document.getElementById(`sidebar`)
        if (highlighted === null || !fromPill) {
            resetLine()
            return
        }
        if (!targetBB) return
        let bbAttrs = targetBB.getClientRect()

        let pillLeftWall = { x: fromPill.getBoundingClientRect().x - galleryDiv.offsetWidth - sidebarDiv.offsetWidth, y: fromPill.getBoundingClientRect().top - 60 }

        let x1, x2, y1, y2;



        x1 = bbAttrs.x + bbAttrs.width + stageContainer.offsetLeft

        if (x1 < stageContainer.offsetLeft) x1 = stageContainer.offsetLeft
        else if (x1 > pillLeftWall.x - 20)
            x1 = pillLeftWall.x - 20

        y1 = bbAttrs.y + (bbAttrs.height / 2) + stageContainer.offsetTop
        if (y1 < stageContainer.offsetTop) y1 = stageContainer.offsetTop
        else if (y1 > stageContainer.offsetHeight + stageContainer.offsetTop) y1 = stageContainer.offsetHeight + stageContainer.offsetTop
        x2 = pillLeftWall.x
        y2 = pillLeftWall.y
        svgLine.x1.baseVal.value = x1
        svgLine.y1.baseVal.value = y1
        svgLine.x2.baseVal.value = x2
        svgLine.y2.baseVal.value = y2
        svgLine.style.stroke = svgColor

        svgCircle.cx.baseVal.value = x2
        svgCircle.cy.baseVal.value = y2
        svgCircle.r.baseVal.value = 3
        svgCircle.style.stroke = svgColor
    */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [highlighted])

    const [IMGLoaded, setIMGLoaded] = useState(false)

    useEffect(() => {
        if (IMGLoaded === false) return;

        let stage = stageRef.current;
        let image = imageLayerRef.current.children[0];
        let center = {
            x: image.width() / 2,
            y: image.height() / 2
        }
        stage.offset(center)
        stage.position({ x: image.getClientRect().width / 2, y: image.getClientRect().height / 2 })


    }, [IMGLoaded])

    const rotate = (angle) => {

        let stage = stageRef.current
        let image = imageLayerRef.current.children[0]

        let center = {
            x: image.width() / 2,
            y: image.height() / 2
        }

        stage.offset(center);

        stage.rotate(angle)

        stage.batchDraw();
    }

    useEffect(() => {
        let canvas = document.getElementById('result-canvas')

        if (!canvas) return;
        setStageSize({ width: canvas.offsetWidth, height: canvas.offsetHeight - 40 })
    }, [])

    const [rotation, setRotation] = useState(0)
    useEffect(() => {
        rotate(rotation)
        if (imageAttrs) {
            rotate(-imageAttrs.rotation_angle)
            setRotation(imageAttrs.rotation_angle)
        }

    }, [imageAttrs])

    const initializeImageSize = () => {
        /*let w = imageLayerRef.current.children[0].getClientRect().width;
        let h = imageLayerRef.current.children[0].getClientRect().height;
        let max = w > h ? w : h
        let canvasMax = w > h ? stageSize.width : stageSize.height

        zoom((canvasMax / max)*1.50)*/
        zoom(zoomValue * 1.5)
    }


    const [newAnnotationRect, setNewAnnotationRect] = useState(null)

    const handleSimpleAnnotationMode = () => {
        setAddSimpleAnnotationMode(!addSimpleAnnotationMode)
    }



    const handleMouseDown = (e) => {
        let layer = e.target.getLayer()
        if (addSimpleAnnotationMode === false || !layer || e.target.getClassName() === 'Rect' || e.target.getClassName() === 'Shape' || annotationRect) return;
        setAnnotationRect(null)

        const { x, y } = layer.getRelativePointerPosition();

        setNewAnnotationRect({ x, y, width: 0, height: 0 })
    }

    const handleMouseMove = (e) => {
        let layer = e.target.getLayer()
        if (addSimpleAnnotationMode === false || !newAnnotationRect || !layer) return;
        const sx = newAnnotationRect.x;
        const sy = newAnnotationRect.y;
        const { x, y } = layer.getRelativePointerPosition();
        setNewAnnotationRect({ x: sx, y: sy, width: x - sx, height: y - sy })
    }

    const handleMouseUp = (e) => {
        if (addSimpleAnnotationMode === false || !newAnnotationRect) return;
        const sx = newAnnotationRect.x;
        const sy = newAnnotationRect.y;
        const swidth = newAnnotationRect.width;
        const sheight = newAnnotationRect.height;
        setNewAnnotationRect(null)
        setAnnotationRect({ id: -1, x: sx, y: sy, width: swidth, height: sheight })
    }

    const annotationRectRef = useRef();
    const transformerRef = useRef();

    useEffect(() => {
        if (!annotationRect) return;
        transformerRef.current.nodes([annotationRectRef.current]);
        transformerRef.current.getLayer().batchDraw();
    }, [annotationRect])

    const handleCreateEditSimpleAnnotationRect = (ann) => {
        bbLayerRef.current.children.forEach((x) => {
            x.show()
        })
        let rectToTransformList = bbLayerRef.current.children.filter(x => {
            return x.attrs.id === `bb-${ann.id}`
        })
        rectToTransformList.forEach((x) => x.hide())
        let points = rectToTransformList[0].attrs.points

        if (rotation < 0)
            setAnnotationRect({ x: points[0], y: points[1], width: points[4] - points[0], height: points[5] - points[1], toShow: rectToTransformList[0] })
        else
            setAnnotationRect({ x: points[0], y: points[1], width: points[2] - points[0], height: points[5] - points[1], toShow: rectToTransformList[0] })

    }


    useEffect(() => {
        if (editSimpleAnnotation) {
            setAddSimpleAnnotationMode(false)
            setNewAnnotationRect(null)

            let found = false;
            let newResult = []
            let toTransform = null;
            for (const x of result) {
                if (x['id'] === editSimpleAnnotation.id) {
                    if (found === false) {
                        newResult.push(x)
                        toTransform = x
                        found = true;
                    }
                } else {
                    newResult.push(x)
                }
            }
            if (toTransform) {
                handleCreateEditSimpleAnnotationRect(toTransform)
            }
        }
        else {
            bbLayerRef.current.children.forEach((x) => {
                x.show()
            })
            setAnnotationRect(null)
        }
    }, [editSimpleAnnotation])

    const checkIfInside = (bb, rect) => {

        /* OLD
         let bbX = bb[0][0]
         let bbY = bb[0][1]
         let bbW = bb[1][0] - bb[0][0]
         let bbH = bb[2][1] - bb[0][1]
 
         if (rect.x < bbX + bbW &&
             rect.x + rect.width > bbX &&
             rect.y < bbY + bbH &&
             rect.y + rect.height > bbY
         ) {
             return true
         }
             */

        let bbOffsetX = (bb[0][0] + bb[1][0]) / 2
        let bbOffsetY = (bb[0][1] + bb[2][1]) / 2
        if (bbOffsetX >= rect.x && bbOffsetX < rect.x + rect.width && bbOffsetY >= rect.y && bbOffsetY < rect.y + rect.height) return true
        //  if (bbX >= rect.x && bbY >= rect.y && bbX < rect.x + rect.width && bbY < rect.y + rect.height)
        //    return true
        return false
    }

    useEffect(() => {
        if (!annotationRect || !OCR || !stageRef) return;
        let tempText = []
        for (let en of OCR) {
            if (en.BoundingBox.length > 0 && checkIfInside(en.BoundingBox, annotationRect))
                tempText.push(en.Text)
        }
        if (tempText.length === 0) {
            setSelectedText('')
        }
        setSelectedText(tempText.join(' '))
    }, [annotationRect])

    function stcRGBA(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }


    return (
        <>
            <SvgLinePortal bbLayerRef={bbLayerRef} />
            <CanvasToolbar zoom={zoom} rotate={rotate} />

            <div className={`absolute z-10 w-full ${addSimpleAnnotationMode || editSimpleAnnotation ? '!opacity-100' : '!opacity-0'} h-full border-[5px] border-primary-color rounded-myb pointer-events-none `}></div>
            <div
                onMouseEnter={(e) => {
                    document.getElementById('toolbar').style.opacity = 1;
                }}
                onMouseLeave={(e) => {
                    if (e.relatedTarget.id === 'toolbar-item') return;
                    document.getElementById('toolbar').style.opacity = 0.2;
                }}
                id={'stage-container'} className="stage-container hover:cursor-move relative">

                <div className="absolute h-screen w-screen z-10 top-0 left-0 pointer-events-none">
                    <svg id="svg" className="absolute h-screen w-screen z-10 top-0 left-0 pointer-events-none">
                        <circle className="stroke-[3] stroke-black fill-white" id="circle" />
                        <line className="stroke-[3] stroke-black" id="line" />

                    </svg>
                </div>



                <Stage
                    ref={stageRef}
                    width={stageSize.width}
                    height={stageSize.height}
                    className="canvas"
                    style={{ position: "absolute", width: '100%', backgroundColor: "#EDEDF8", borderRadius: "10px" }}
                    draggable={!addSimpleAnnotationMode && IMGLoaded}
                    x={0}
                    y={0}

                    onMouseDown={IMGLoaded && handleMouseDown}
                    onMouseUp={IMGLoaded && handleMouseUp}
                    onMouseMove={IMGLoaded && handleMouseMove}
                >

                    <Layer ref={imageLayerRef} id="img"
                        offset={{ x: 0, y: 0 }}>
                        <ImageFromUrl imageUrl={fileURL + "&format=original"} initializeImageSize={initializeImageSize} setIMGLoaded={setIMGLoaded} />
                    </Layer>
                    <Layer ref={bbLayerRef} id='bb-layer'>
                        {IMGLoaded && result && result.map((entity, index) =>

                            entity.BoundingBox && <Line
                                name={`bb-${entity.label}`}
                                id={`bb-${entity.id}`}
                                key={index}
                                closed
                                points={entity.BoundingBox}
                                scaleX={1}
                                scaleY={1}
                                opacity={0.3}
                                stroke={stc(entity.label)}
                                strokeWidth={5}

                            />

                        )}
                    </Layer>
                    <Layer>
                        {newAnnotationRect &&
                            <Rect
                                x={newAnnotationRect.x}
                                y={newAnnotationRect.y}
                                width={newAnnotationRect.width}
                                height={newAnnotationRect.height}
                                fill="transparent"
                                stroke="black"
                            />
                        }
                    </Layer>
                    <Layer>
                        {annotationRect &&
                            <Rect
                                ref={annotationRectRef}
                                id={annotationRect.id}
                                x={annotationRect.x}
                                y={annotationRect.y}
                                width={annotationRect.width}
                                height={annotationRect.height}
                                fill={editSimpleAnnotation ? stcRGBA(stc(editSimpleAnnotation.label), 0.15) : `rgba(0,0,0,0.07)`}
                                stroke="rgba(0,0,0,0.2)"
                                strokeScaleEnabled={false}
                                onDragEnd={(e) => {

                                    let attrs = {
                                        x: transformerRef.current._nodes[0].getTransform().m[4],
                                        y: transformerRef.current._nodes[0].getTransform().m[5],
                                        width: transformerRef.current._nodes[0].attrs.width * transformerRef.current._nodes[0].getTransform().m[0],
                                        height: transformerRef.current._nodes[0].attrs.height * transformerRef.current._nodes[0].getTransform().m[3]
                                    }
                                    let tempText = []
                                    for (let en of OCR) {
                                        if (en.BoundingBox.length > 0 && checkIfInside(en.BoundingBox, attrs))
                                            tempText.push(en.Text)
                                    }
                                    if (tempText.length === 0) {
                                        setSelectedText('')
                                    }
                                    else
                                        setSelectedText(tempText.join(' '))
                                }}

                                onTransformEnd={(e) => {

                                    let attrs = {
                                        x: transformerRef.current._nodes[0].getTransform().m[4],
                                        y: transformerRef.current._nodes[0].getTransform().m[5],
                                        width: transformerRef.current._nodes[0].attrs.width * transformerRef.current._nodes[0].getTransform().m[0],
                                        height: transformerRef.current._nodes[0].attrs.height * transformerRef.current._nodes[0].getTransform().m[3]
                                    }

                                    let tempText = []
                                    for (let en of OCR) {
                                        if (en.BoundingBox.length > 0 && checkIfInside(en.BoundingBox, attrs))
                                            tempText.push(en.Text)
                                    }
                                    setSelectedText(tempText.join(' '))
                                }}

                            />

                        }
                    </Layer>
                    <Layer id="transformer-layer">
                        {(annotationRect || editSimpleAnnotation) &&
                            <Transformer
                                ref={transformerRef}
                                flipEnabled={false}
                                draggable
                                boundBoxFunc={(oldBox, newBox) => {
                                    // limit resize

                                    if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {

                                        return oldBox;
                                    }
                                    return newBox;
                                }}

                                shouldOverdrawWholeArea={true}
                                rotateEnabled={false}
                                rotateAnchorOffset={rotation}
                                anchorStroke="#1a3066"
                                anchorSize={6}
                                borderStrokeWidth={3}
                                borderStroke="#1a3066"
                                anchorCornerRadius={50}
                                borderDash={[5, 5]}

                            />

                        }
                    </Layer>
                </Stage>
            </div>
        </>
    )
}