import { useEffect, useState } from "react"
import * as API from '../../apis/api'
import { getModelInfo } from "../products/products"

export default function CatalogItem({ item, select, fallback, type = "USE_CASE" }) {

    const stub_props = {
        service: {},
        image_url: "https://assets-global.website-files.com/643edefedd2d924e7c378774/64ba97f5207f66103efa354d_Frame%208420.svg",
        name: "USE CASE NAME",
        task: "Information Extraction"
    }
    const [status, setStatus] = useState('ACTIVE')

    const [imagePreview, setImagePreview] = useState(null);
    const [modelType, setModelType] = useState(null);

    useEffect(() => {

        const fetchUseCaseStatus = async () => {

            await API.getResourceJobs('USE_CASE', item.public_id).then((jobs) => {
                jobs.forEach((job) => {
                    if (job.find((x) => x === 'USE_CASE_DELETE'))
                        setStatus('DELETING')
                })
            })

            await API.getModel(item.model).then((res) => {
                setImagePreview(res.data['image_preview_url'].length > 0 ? res.data['image_preview_url'] : 'default')
                setModelType(res.data['model_type'] === "IE" ? "Information Extraction" : "Classification")
            })

        }
        if (type === 'MODEL') setImagePreview(item.image_preview_url.length > 0 ? item.image_preview_url : 'default')
        else fetchUseCaseStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const types = {
        'IE': 'Information Extraction'
    }
    return (
        status === 'ACTIVE' && imagePreview &&
        <div className="h-full w-full flex">
            <div id={item.name} role="listitem" className="catalog-item">
                <div className="catalog-item-container" onClick={() => { fallback && fallback(item) }}>

                    <div className="h-3/4">
                        {
                            imagePreview !== 'default' ?

                                <img src={imagePreview} className='relative h-full' />

                                :
                                getModelInfo('default').svg
                        }

                    </div>
                    <div className="h-1/4">
                        <span className="text-card-title w-full flex items-center justify-center">
                            {item.name}
                        </span>

                        <div className="catalog-item-footer">
                            <label className='service-card-badge'>
                                {item.model_type ? types[item.model_type] : 'Information Extraction'}
                            </label>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}