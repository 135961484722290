import { Button } from "components/button/Button";
import { useState } from "react";
type Props = {
  backCallback: () => void;
  savePageRange: (isAll: boolean, a: number, b: number) => void;
};

export const StepPages = ({ backCallback, savePageRange }: Props) => {
  const handleBackBtn = () => {
    backCallback();
  };

  const [a, setA] = useState<string>("0");
  const [b, setB] = useState<string>("1");
  const [isAll, setIsAll] = useState<boolean>(false);
  const [btnSelected, setBtnSelected] = useState<boolean>(false);
  const [rangeSelected, setRangeSelected] = useState<boolean>(false);

  const checkValidRange = (startRange: string, endRange: string) => {
        return Number(startRange) <= Number(endRange);
  }

  return (
    <div className="relative h-full w-full modal-grid">
      <div className="w-full flex flex-row items-center justify-center">
        <span className="text-subtitle-small">
          Select the page range for the policy rule.
        </span>
      </div>

      <div className="w-full flex flex-col items-center justify-center gap-5">
        <div
          className={`w-full flex flex-row items-center justify-center gap-5 h-1/3 p-5 ${
            isAll ? " border-primary-color" : ""
          } rounded-myb hover:bg-slate-100 !transition-all !duration-200 border-2`}
          onClick={() => {
            setBtnSelected(true);
            setRangeSelected(false);
            setIsAll(true);
          }}
        >
          <div className={"hidden md:inline p-1"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={36}
              height={36}
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
              />
            </svg>
          </div>

          <div className={"flex items-center p-4 h-14 "}>
            <span className="pointer-events-none text-sm font-semibold text-center">
              All Pages
            </span>
          </div>

          <div className={"items-center px-5 hidden sm:inline"}>
            <span className="pointer-events-none text-xs text-center">
              Choose this option to apply the policy to all the document pages
            </span>
          </div>
        </div>

        <div
          className={`w-full flex flex-row items-center justify-center gap-5 h-1/3 p-5 ${
            rangeSelected ? " border-primary-color" : ""
          } rounded-myb hover:bg-slate-100 !transition-all !duration-200 border-2`}
          onClick={() => {
            setBtnSelected(true);
            setRangeSelected(true);
            setIsAll(false);
          }}
        >
          <div className={"hidden md:inline p-1"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={36}
              height={36}
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>
          </div>

          <div className={"flex items-center p-0 h-14 "}>
            <span className="pointer-events-none text-sm font-semibold text-center">
              Page Range
            </span>
          </div>

          <div className="h-20 w-2/3 flex flex-row justify-center items-center gap-10">
            <div className="flex flex-row gap-3 text-sm items-center">
              From{" "}
              <input
                type="number"
                className="w-10 h-8 px-2 border rounded-myb"
                min={0}
                value={a}
                onChange={(e) => {
                  setA(e.target.value);
                }}
              />
            </div>

            <div className="flex flex-row gap-3 rounded-myb text-sm items-center">
              To{" "}
              <input
                type="number"
                className="w-10 h-8 px-2 border rounded-myb"
                min={1}
                value={b}
                onChange={(e) => {
                  setB(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
        {!checkValidRange(a, b) && !isAll && <div><span className={"text-red-400"}>Please select a valid page range</span></div>}
    
      <div className="sticky flex flex-row items-center justify-center gap-5">
        <Button
          action={() => {
            handleBackBtn();
          }}
          rounded
        >
          Go Back
        </Button>

        {(isAll || checkValidRange(a, b) && !isAll) ?
        <Button
          action={() => {
            savePageRange(isAll, Number(a), Number(b));
          }}
          rounded
          disabled={!btnSelected}
        >
          Next
        </Button>
            :
            <Button
                action={() => {
                  savePageRange(isAll, Number(a), Number(b));
                }}
                rounded
                disabled={true}
            >
              Next
            </Button>
        }

      </div>
    </div>
  );
};
