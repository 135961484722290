import { useEffect, useState } from "react";
import { modelsEntityOrder } from "utils/modelsEntityOrder";
import { AggregatedAnnotationPanel } from "./aggregatedAnnotationPanel";
import { SimpleAnnotationPanel } from "./simpleAnnotationPanel";
interface Dict {
  [key: string]: any;
}

type Alert = {
  title: string;
  description: string;
  onConfirm: () => any;
  showCustomAlert: boolean;
};

type ResultProps = {
  currentUseCase: Dict;
  inference: Dict;
  unusedLabels: Array<any>;
  handleEditField: (
    label: string,
    newField: string,
    value_index?: number
  ) => {};
  refetchInferenceResult: () => void;
  setDefaultAddLabel: React.Dispatch<React.SetStateAction<string>>;
  setAddSimpleAnnotationMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddNewAnnotation: (entity: any) => Promise<any>;
  handleEditAnnotation: (entity: any) => Promise<void>;
  handleDeleteAnnotation: (entity: any) => Promise<void>;
  setEditSimpleAnnotation: React.Dispatch<React.SetStateAction<any>>;
  editSimpleAnnotation: any;
  setAlertData: React.Dispatch<React.SetStateAction<Alert>>;
};
export const ResultList: React.FC<ResultProps> = ({
  currentUseCase,
  inference,
  unusedLabels,
  refetchInferenceResult,
  setDefaultAddLabel,
  setAddSimpleAnnotationMode,
  handleAddNewAnnotation,
  handleEditField,
  handleEditAnnotation,
  handleDeleteAnnotation,
  setEditSimpleAnnotation,
  editSimpleAnnotation,
  setAlertData,
}) => {

  const [nAggregatedFields, setNAggregatedFields] = useState<null | number>(null)

  useEffect(() => {
    if (!inference || !inference['aggregated_entities']) return;
    let n = 0;
    let aggEnts = inference['aggregated_entities']
    for(let x in aggEnts){
      n += aggEnts[x].length
    }
    setNAggregatedFields(() => n)
  }, [inference])
  const panels = {
    Fields: (
      <SimpleAnnotationPanel
        modelOrder={
          currentUseCase &&
            currentUseCase.model &&
            modelsEntityOrder[currentUseCase.model]
            ? modelsEntityOrder[currentUseCase.model]
            : null
        }
        labels={
          currentUseCase && currentUseCase.labels && currentUseCase.labels
        }
        inference={inference}
        unusedLabels={unusedLabels}
        handleEditField={handleEditField}
        handleEditAnnotation={handleEditAnnotation}
        handleDeleteAnnotation={handleDeleteAnnotation}
        setDefaultAddLabel={setDefaultAddLabel}
        setAddSimpleAnnotationMode={setAddSimpleAnnotationMode}
        handleAddNewAnnotation={handleAddNewAnnotation}
        setEditSimpleAnnotation={setEditSimpleAnnotation}
        setAlertData={setAlertData}
      />
    ),
    Aggregated: (
      <AggregatedAnnotationPanel
        inference={inference}
        labels={
          currentUseCase && currentUseCase.labels && currentUseCase.labels
        }
        refetchInferenceResult={refetchInferenceResult}
        setAddSimpleAnnotationMode={setAddSimpleAnnotationMode}
        handleAddNewAnnotation={handleAddNewAnnotation}
        setAlertData={setAlertData}
      />
    ),
  };

  const [panel, setPanel] = useState<"Fields" | "Aggregated">("Fields");
  const [aggregationPanelDisabled, setAggregationPanelDisabled] =
    useState(false);
  useEffect(() => {
    setAddSimpleAnnotationMode(false);
    setEditSimpleAnnotation(null);
  }, [panel]);

  useEffect(() => {
    if (!currentUseCase) return;
    let check = false;
    for (let x in currentUseCase.labels) {
      if (currentUseCase.labels[x].tag_aggregation !== null) {
        check = true;
        return;
      }
    }

    if (check === false) setAggregationPanelDisabled(true);
  }, [currentUseCase]);
  return (
    <div
      id="fields-list"
      className="relative h-[calc(100%_-_80px)] overflow-hidden max-h-full flex flex-col items-center gap-3 px-2 py-5 w-full bg-white rounded-myb"
    >
      <div className="!h-8 !min-h-10 w-full rounded-t-myb flex flex-row border-b shadow text-title-xsmall">
        <div
          className={`w-1/2 flex items-center justify-center cursor-pointer ${panel === "Fields" && "bg-primary-color text-white rounded-t-myb"
            }`}
          onClick={() => {
            if (panel !== "Fields") setPanel("Fields");
          }}
        >
          Fields
        </div>
        <button
          className={`w-1/2 flex items-center justify-center ${panel === "Aggregated" &&
            "bg-primary-color text-white rounded-t-myb"
            }`}
          onClick={() => {
            if (panel !== "Aggregated") setPanel("Aggregated");
          }}
          disabled={aggregationPanelDisabled}
        >
          Aggregated Fields {!aggregationPanelDisabled && nAggregatedFields !== null && `${'(' + nAggregatedFields + ')'}`}
        </button>
      </div>
      {currentUseCase &&
        currentUseCase.labels &&
        inference &&
        Object.keys(inference).length > 0 && <>{panels[panel]}</>}
    </div>
  );
};
