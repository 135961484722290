
import * as API from 'apis/api';
import { UcPlusSVG } from "assets/dashboard/UcPlusSVG";
import { fetchUser, userIsAdmin } from "auth/auth";
import { Button } from "components/button/Button";
import UseCasesCard from "components/dashboard/useCasesCard";
import { useNotifications } from 'components/notificationSystem/notificationContext';
import { identifyJune } from "helpers/hooks/useJune";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
export function DashboardRoute({ setFirstUse, userInfo, analytics }) {

    const [useCaseList, setUseCaseList] = useState(null)
    const [freePlanCount, setFreePlanCount] = useState(null);

    const notification = useNotifications()

    const fetchUseCaseList = useCallback(async () => {
        let allUseCases = [];
        let currentPage = 0;
        let lastPage = 0;
        let total = 0;
        await API.listUserUseCases(0, 50).then(async res => {
            total = res.total;
            allUseCases = res['items_data'];
            currentPage = res['current_page'];
            lastPage = res['last_page'];
            return res
        }).catch(() => {
            notification.addNotification({
                title: "Error",
                message: "Error fetching your use cases. Try to refresh the page, or contact us.",
                severity: "ERROR",
                timeout: 5000
            })
        })
        if (total === 0) setFirstUse(true)
        if (lastPage > 0) {
            for (let i = 1; i <= lastPage; i++) {
                await API.listUserUseCases(i, 50).then((res) => {
                    allUseCases = [...allUseCases, ...res['items_data']];
                }).catch(() => {
                    notification.addNotification({
                        title: 'Error',
                        message: "Can't create a new use case at the moment.Try later.",
                        severity: "ERROR",
                        timeout: 3000
                    });
                });
            }
        }
        setUseCaseList(allUseCases)
    }, [setFirstUse])

    const fetchPlanInfo = useCallback(async () => {
        await API.getOrganizationPlans().then(res => {
            setFreePlanCount(() => res['free_plan_count'])
        }).catch(() => {
            notification.addNotification({
                title: 'Error',
                message: "Can't fetch your plan infos.",
                severity: "ERROR",
                timeout: 3000
            });
        })
    })
    useEffect(() => {
        if (!useCaseList) {
            fetchUseCaseList();
            fetchPlanInfo();
        }

    }, [useCaseList, fetchUseCaseList])

    useEffect(() => {
        userInfo && identifyJune(fetchUser(), userInfo, analytics)
    }, [userInfo])

    const navigate = useNavigate();

    return (
        <div className="product-bg default-page-layout">
            <div className='default-page-container'>
                <div className="page-header">

                    <div className="text-page-title">Welcome {userInfo && userInfo.name}</div>
                    {
                        freePlanCount !== null ? <Button className="!rounded text-[20px] px-10 disabled:pointer-events-none flex gap-1" action={() => { navigate('/create-usecase') }}
                            disabled={
                                !userIsAdmin() && freePlanCount >= 3
                            }> <UcPlusSVG width={26} height={26} /> New Use Case</Button> : <></>
                    }


                </div>


                {useCaseList &&
                    <UseCasesCard useCaseList={useCaseList} admin={userIsAdmin()} />
                }


            </div>
        </div>
    )
}