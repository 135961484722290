import { useState } from "react";
import { Button } from "components/button/Button";
type Props = {
  selectPolicyType: (x: "RequiredFields" | "FieldMinConfidence") => void;
  closePanel: () => void;
};

export const StepPolicyType = ({ selectPolicyType, closePanel }: Props) => {
  const [type, setType] = useState<
    "RequiredFields" | "FieldMinConfidence" | null
  >(null);

  return (
    <div className="relative h-full w-full modal-grid grow">
      <div className="w-full flex flex-row items-center justify-center">
        <span className="text-subtitle-small">Select the Policy Type</span>
      </div>

      <div className="w-full flex flex-col items-center justify-center gap-5">
        <div
          className={`w-full flex flex-row items-center justify-center gap-5 h-1/3 p-5 ${
            type === "RequiredFields" ? " border-primary-color" : ""
          } rounded-myb hover:bg-slate-100 !transition-all !duration-200 border-2`}
          onClick={() => {
            setType("RequiredFields");
          }}
        >
          {/*<div className={"hidden md:inline"}>

                  <svg xmlns="http://www.w3.org/2000/svg"  width={36} height={36} fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="size-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
                  </svg>

              </div>*/}

          <div className={"flex items-center p-4 h-14 "}>
            <span className="pointer-events-none text-sm font-semibold text-center">
              Mandatory Labels
            </span>
          </div>
          <div className={"items-center px-5 hidden lg:inline"}>
            <span className="pointer-events-none text-xs text-center">
              This policy type helps users to identify missing expected labels.
            </span>
          </div>
        </div>

        <div
          className={`w-full flex flex-row items-center justify-center gap-5 h-1/3 p-5 ${
            type === "FieldMinConfidence" ? " border-primary-color" : ""
          } rounded-myb hover:bg-slate-100 !transition-all !duration-200 border-2`}
          onClick={() => {
            setType("FieldMinConfidence");
          }}
        >
          {/*<div className={"hidden md:inline"}>

                  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                  </svg>

              </div>*/}

          <div className={"flex items-center p-8 h-14 "}>
            <span className="pointer-events-none text-sm font-semibold text-center">
              Label Score
            </span>
          </div>
          <div className={"items-center px-5 hidden lg:inline"}>
            <span className="pointer-events-none text-xs text-center">
              This policy helps users set the minimum score value for a label to
              avoid review.
            </span>
          </div>
        </div>
      </div>
      <div className="sticky w-full flex flex-row items-center justify-center gap-5">
        <Button
          action={() => {
            closePanel();
          }}
          rounded
        >
          Close
        </Button>
        <Button
          action={() => {
            if (type) selectPolicyType(type);
          }}
          disabled={!type}
          rounded
        >
          Next
        </Button>
      </div>
    </div>
  );
};
