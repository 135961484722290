import { useState, useRef, useEffect } from "react";

type Alert = {
  title: string;
  description: string;
  onConfirm: () => any;
  showCustomAlert: boolean;
};

type Props = {
  alertData: Alert;
  setAlertData: React.Dispatch<React.SetStateAction<Alert>>;
  children: JSX.Element[];
};

export const CustomAlert = ({ alertData, setAlertData, children }: Props) => {
  const alertDiv = useRef<HTMLDivElement | null>(null);
  const containerDiv = useRef<HTMLDivElement | null>(null);

  const [alertClassName, setAlertClassName] = useState<string>("");
  const [alertDisplay, setAlertDisplay] = useState<string>("none");
  const { title, description, onConfirm, showCustomAlert } = alertData;

  const showAlert = () => {
    setAlertClassName("!opacity-100");
    setAlertDisplay("flex");
    alertDiv.current?.classList.add("alert-in");
  };

  const hideAlert = () => {
    setAlertClassName("!opacity-0");
    setAlertDisplay("none");
  };

  useEffect(() => {
    if (showCustomAlert) {
      showAlert();
    } else {
      hideAlert();
    }
  }, [showCustomAlert]);

  return (
    <div className="">
      <div
        ref={containerDiv}
        className={`absolute w-screen h-screen z-[100] bg-black bg-opacity-30 !pointer-events-auto items-start justify-center ${alertClassName}`}
        style={{
          display: alertDisplay,
        }}
      >
        <div
          ref={alertDiv}
          className="absolute w-fit min-w-[250px] max-w-[350px] shadow-sm h-32 rounded-myb bg-white top-10 flex flex-col justify-start p-2 !opacity-100"
        >
          <div className="relative h-1/4 text-subtitle">{title}</div>
          <div className="relative h-2/4 text-sm">{description}</div>
          <div className="relative h-1/4 w-full flex justify-end pointer-events-auto gap-4">
            <button
              className="bg-white border rounded-myb px-2 "
              onClick={() => {
                setAlertData({ ...alertData, showCustomAlert: false });
              }}
            >
              Cancel
            </button>
            <button
              className="bg-primary-color text-white border rounded-myb px-2 border-primary-color"
              onClick={() => {
                onConfirm();
                setAlertData({ ...alertData, showCustomAlert: false });
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};
