import * as API from 'apis/api';
import { ExtractSVG } from 'assets/usecase/ExtractSVG';
import { TrashSVG } from 'assets/usecase/TrashSVG';
import { Button } from 'components/button/Button';
import { useNotifications } from 'components/notificationSystem/notificationContext';
import { useEffect, useRef, useState } from 'react';

export default function DocumentRow({ entry, navigateCallback, handleExportDoc, analytics, setDeleteLoading }) {

    const [doc, setDoc] = useState()
    const [status, setStatus] = useState('')
    const [loading, setLoading] = useState(false)
    let interval = useRef()

    const notification = useNotifications()


    const revisionColor = (status, alpha) => {
        let colors = {
            'approved': `rgba(0,255,0,${alpha})`,
            'not_approved': `rgba(255,0,0,${alpha})`,
            'not_ready': `rgba(112,128,144,${alpha})`, //da nascondere
            'to_review': `rgba(255,175,0,${alpha})`,
            'pending': `rgb(0,255,220,${alpha})`, //no operazioni fatte: da capire come mostrare
            'review_required': `rgb(255,230,0,${alpha})`, //no operazioni fatte: da capire come mostrare
        }

        return colors[status];
    }
    const revisionMapping = {
        'approved': { text: 'Approved', color: revisionColor('approved', 0.85), border: revisionColor('approved', 0.25) },
        'not_approved': { text: 'Rejected', color: revisionColor('not_approved', 0.85), border: revisionColor('not_approved', 0.25) },
        'not_ready': { text: 'Not Ready', color: revisionColor('not_ready', 0.85), border: revisionColor('not_ready', 0.25) },
        'to_review': { text: 'To Review', color: revisionColor('to_review', 0.85), border: revisionColor('to_review', 0.25) },
        'pending': { text: 'New', color: revisionColor('pending', 0.85), border: revisionColor('pending', 0.25) },
        'review_required': { text: 'Review Required', color: revisionColor('review_required', 0.85), border: revisionColor('review_required', 0.25) },
    }


    const fetchStatus = async () => {
        interval.current = setInterval(async () => {
            if (status === 'ACTIVE' || status === 'ERROR' || status === 'DELETE') {
                setLoading(false)
                clearInterval(interval.current)
            } else {
                setLoading(true)
                await API.getDocument(entry.use_case, doc.public_id).then((res) => {
                    if (res.status === 'ACTIVE' || res.status === 'ERROR' || res.status === 'DELETE') {
                        setLoading(false)
                        setStatus(res.status)
                        setDoc(res)
                        clearInterval(interval.current)
                    }
                }).catch((e) => {
                    setLoading(false)
                    clearInterval(interval.current)
                })
            }
        }, 2000)

    }

    useEffect(() => {
        if (!entry || (doc && (entry.public_id === doc.public_id))) return;
        setDoc(entry)
        setStatus(entry.status)
    }, [entry])

    useEffect(() => {
        if (status !== 'DELETE' && doc)
            fetchStatus()

        return () => clearInterval(interval.current)
    }, [doc])

    const showInfoPopup = (opacity) => {
        statusInfoPopup.current.style.opacity = opacity
    }

    const statusIcon = (status) => {
        switch (status) {
            case 'ACTIVE':
                return <div id='status-point' className='w-3 h-3 bg-green-500 rounded-full mx-4 !cursor-pointer' onMouseEnter={
                    (e) => {
                        showInfoPopup(1)
                    }}
                    onMouseLeave={
                        (e) => {
                            showInfoPopup(0)
                        }} />
            case 'RUNNING':
                return <div id='status-point' className='w-3 h-3 bg-yellow-500 rounded-full animate-pulse mx-4 !cursor-pointer'
                    onMouseEnter={
                        (e) => {
                            showInfoPopup(1)
                        }}
                    onMouseLeave={
                        (e) => {
                            showInfoPopup(0)
                        }} />
            case 'ERROR':
                return <div id='status-point' className='w-3 h-3 bg-red-500 rounded-full mx-4 !cursor-pointer'
                    onMouseEnter={
                        (e) => {
                            showInfoPopup(1)
                        }}
                    onMouseLeave={
                        (e) => {
                            showInfoPopup(0)
                        }} />
            case 'DELETE':
                return <div id='status-point' className='w-3 h-3 bg-red-500 rounded-full mx-4 !cursor-pointer'
                    onMouseEnter={
                        (e) => {
                            showInfoPopup(1)
                        }}
                    onMouseLeave={
                        (e) => {
                            showInfoPopup(0)
                        }} />
            default:
                return <div id='status-point' className='w-3 h-3 rounded-full mx-4 !cursor-pointer' />
        }
    }



    /*  const deleteDoc = async () => {
  
          await API.deleteDocument(entry.use_case, entry.public_id).then((res) => {
              setStatus('DELETE')
              setLoading(true)
              checkDeleteProcess()
          }).catch((e) => alert('Cannot delete this file.'))
      }
  */

    const deleteDoc = async (doc) => {
        await API.deleteDocument(doc.use_case, doc.public_id).then(() => {
            setStatus('DELETE')
            setDeleteLoading(true)
        }).catch(() => {
            alert('Error deleting doc')
        })
    }

    const statusInfoPopup = useRef()





    const tooltipRef = useRef()

    const showToolTip = (e) => {
        tooltipRef.current.style.opacity = 1
    }

    const hideToolTip = (e) => {
        tooltipRef.current.style.opacity = 0
    }

    return (
        <div className="tr-container relative">
            <div ref={tooltipRef} className='absolute flex flex-row wrap justify-start items-end w-auto h-auto bg-white shadow border rounded-myb px-2 py-1 pointer-events-none !z-20 !transition-opacity !duration-300'
                style={{
                    opacity: 0,
                    top: '-20px',
                    left: '35px',
                }}>
                <span className='text-start text-sm font-thin'>
                    {doc && doc.document_metadata.original_name}
                </span>
            </div>
            {doc && <div className='tr' onClick={(e) => {
                if (e.target.tagName === 'INPUT' || e.target.tagName === 'BUTTON' || e.target.tagName === 'IMG' || e.target.id === 'status-point' || e.target.id === 'btn')
                    return
                if (status === 'ACTIVE')
                    navigateCallback(doc)
            }} style={{ cursor: status === 'ACTIVE' ? 'pointer' : 'default' }} >
                <div id="t-name">
                    <span onMouseEnter={(e) => {
                        showToolTip(e)
                    }}

                        onMouseLeave={(e) => {
                            hideToolTip(e)
                        }}>
                        {doc.document_metadata.original_name}
                    </span>

                </div>
                <div id="t-status">
                    <div ref={statusInfoPopup}
                        className='absolute h-fit w-fit z-10 ms-7 mb-10 px-3 py-1 bg-white rounded shadow flex items-center'
                        style={{ opacity: 0 }}>
                        {status}
                    </div>
                    {statusIcon(status)}
                </div>
                <div id="t-createdat">
                    {doc.created.split('T')[0].split('-').reverse().join('/')}
                </div>
                <div id="t-revision_status">
                    <div className='revision-status-pill' style={{
                        borderColor: revisionMapping[doc.revision_status].color,
                        backgroundColor: revisionMapping[doc.revision_status].border
                    }}>
                        {revisionMapping[doc.revision_status].text}
                    </div>
                </div>
                <div id="t-export">
                    <Button className=' !min-w-0 !w-8 h-8 p-1 !border border-slate-300 hover:bg-blue-200 hover:border-blue-400' action={() => {

                        handleExportDoc(doc, entry.use_case, analytics, notification)
                    }}
                        disabled={status === 'DELETE'}>
                        <ExtractSVG width={22} height={22} id='btn' />
                    </Button>
                </div>
                <div id="t-delete">
                    <Button className='!w-8 !min-w-0 h-8 p-1 !border border-slate-300 hover:bg-red-300 hover:border-red-500' disabled={loading} action={() => {
                        deleteDoc(doc)
                    }}>
                        <TrashSVG width={'100%'} height={'100%'} id='btn' />
                    </Button>
                </div>
            </div>}

        </div>

    )
}