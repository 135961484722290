import { useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

type GalleryProps = {
  files: {};
  currentFile: string;
  navigateToPage: (page: string) => {};
};

export const ResultGallery: React.FC<GalleryProps> = ({
  files,
  currentFile,
  navigateToPage,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToResult = () => {
    const useCase = location.pathname.split("/")[2];
    navigate({ pathname: `/usecase/${useCase}/documents` }, { replace: false });
  };

  const [openGallery, setOpenGallery] = useState(true);

  return (
    <div
      id="gallery"
      className="relative flex flex-col w-min-[150px] w-[150px]  bg-[#EDEDF7] border-e-2 border-slate-300 !transition-all !duration-50 "
      style={{
        minWidth: openGallery ? "150px" : "0px",
        width: openGallery ? "150px" : "0px",
      }}
    >
      {
        // This is the button that toggles the gallery
        /*
        <div
          className=" absolute left-full ms-6 mt-2 z-10 border rounded-full w-28 h-12 bg-white flex items-center justify-center  shadow-md"
          onClick={() => {
            setOpenGallery((old) => !old);
          }}
            
        >
          <span>{openGallery ? "Hide gallery" : "Show gallery"}</span>
        </div>
        */
      }
      <div
        className={
          "h-10 flex items-center text-center my-1 p-5 cursor-pointer "
        }
      >
        <div
          onClick={() => navigateToResult()}
          className={
            "rounded-myb border border-primary-color bg-slate-100 text-primary-color items-center text-center px-3 mx-2 py-1 w-full text-sm flex flex-row gap-2"
          }
        >
          <svg
            width={20}
            height={20}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>

          <span>Back</span>
        </div>
        <div></div>
      </div>
      <div className={"overflow-y-auto"}>
        {files &&
          Object.entries(files).map(([dpage, src]) => (
            <div
              id={dpage}
              key={dpage}
              className="w-full p-2"
              onClick={() => {
                if (dpage === currentFile) return;
                navigateToPage(dpage);
              }}
            >
              <img
                src={`${src}&format=thumbnail`}
                className="border-4 rounded-sm"
                style={{
                  borderColor: dpage === currentFile ? "yellow" : "lightgrey",
                }}
                alt=""
              />
            </div>
          ))}
      </div>
    </div>
  );
};
