import { Button } from "components/button/Button";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Hourglass } from "react-loader-spinner";
import "../../App.css";
import { DateRangeCalendarIcon } from "../../assets/export/DateRangeCalendarIcon";
import { FilterPill } from "../filterPill/filterPill";

export default function ExportPanel({ afterConfirmExport, loading }) {

    const [selectedRevisionStatusFilter, setSelectedRevisionStatusFilter] = useState("approved")
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const closePanel = () => {
        document.querySelector(".overlay-export").style.display = 'none'
    }

    function formatDateToYYYYMMDD(date) {
        if (!date)
            return null
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const ExampleCustomInput = forwardRef(
        ({ value, onClick, className }, ref) => (
            <button className={className} onClick={onClick} ref={ref}>
                {!value ? "Select Date Range" : value}
            </button>
        ),
    );

    return (
        <div className="export-container">
            <button
                id="loader-reset-btn"
                className="absolute -top-10 -left-10"
                onClick={() => closePanel()}
            />
            <div className="bg-white p-5 !min-w-[600px] w-5/6 h-fit rounded-myb flex flex-col gap-5 !transition-all !duration-1000 !ease-in-out">
                <div className="relative w-full h-fit flex flex-col gap-1">
                    <h1 className="text-2xl font-bold text-primary-color">
                        Export Document Results
                    </h1>
                </div>
                <div className={"flex flex-col"}>
                    <span className="font-small text-slate-500 mt-3 mb-3">
                        1. Please select the <b>review status</b> of results.
                    </span>
                    <div className='w-fit flex flex-row gap-2 mb-2'>
                        <FilterPill filterItem={'approved'} filterSelected={selectedRevisionStatusFilter} callbackAction={() => setSelectedRevisionStatusFilter("approved")} > Approved </FilterPill>
                        <FilterPill filterItem={'to_review'} filterSelected={selectedRevisionStatusFilter} callbackAction={() => setSelectedRevisionStatusFilter("to_review")} > To Review </FilterPill>
                        <FilterPill filterItem={'not_approved'} filterSelected={selectedRevisionStatusFilter} callbackAction={() => setSelectedRevisionStatusFilter("not_approved")} > Rejected </FilterPill>
                        <FilterPill filterItem={'review_required'} filterSelected={selectedRevisionStatusFilter} callbackAction={() => setSelectedRevisionStatusFilter("review_required")} > Review Required </FilterPill>
                        <FilterPill filterItem={'All'} filterSelected={selectedRevisionStatusFilter} callbackAction={() => setSelectedRevisionStatusFilter("All")} > All </FilterPill>
                    </div>
                </div>
                <div className={"flex flex-col h-44"}>
                    <span className="font-small text-slate-500 mt-5 mb-3">
                        2. You can specify the <b>date range</b> for your export.  </span>
                    <div className={"flex flex-row gap-5 my-2 mx-3"}>
                        <div className={"p-1"}>
                            <DateRangeCalendarIcon width={24} height={24} />
                        </div>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            dateFormat="dd/MM/yyyy"

                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                            customInput={<ExampleCustomInput className="example-custom-input" />}

                        />
                    </div>
                    <div className="spinner-container">
                        <Hourglass
                            visible={loading}
                            height="40"
                            width="40"
                            radius="36"
                            colors={['#1a3066', '#72a1ed']}
                            wrapperClass="blocks-wrapper"
                            wrapperStyle={{}}
                        />
                    </div>
                </div>
                <div>
                    <span className="font-small text-slate-500 mt-3 mb-3">
                        If you don't specify a date range, all processed data <b>up to today</b> will be exported.
                    </span>
                </div>
                <div className="h-10 w-full flex flex-row justify-end">
                    <Button
                        inverted
                        action={() =>
                            afterConfirmExport(selectedRevisionStatusFilter === "All" ? null : selectedRevisionStatusFilter, formatDateToYYYYMMDD(dateRange[0]), formatDateToYYYYMMDD(dateRange[1]))}
                        disabled={loading}
                    >
                        Export
                    </Button>
                </div>
            </div>
        </div>
    )
}