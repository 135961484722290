import { Button } from "components/button/Button";
import { useEffect, useState } from "react";
type Props = {
    backCallback: () => void;
    policyType: "RequiredFields" | "FieldMinConfidence" | null | undefined;
    selectedLabels: Array<any> | null;
    selectedScoreLabel: any;
    isAllPages: boolean;
    fromAToB: any;
    createCallback: null | ((name: string) => void);
    newIndex: number;
    infoName?: string;
};

export const StepResume = ({
    backCallback,
    policyType,
    selectedLabels,
    selectedScoreLabel,
    isAllPages,
    fromAToB,
    createCallback,
    newIndex,
    infoName,
}: Props) => {
    const handleBackBtn = () => {
        backCallback();
    };

    const [generatedName, setGeneratedName] = useState<string | null>();
    const [name, setName] = useState<string>("");
    const generateName = () => {
        let newName = String(newIndex) + "_";
        if (policyType === "RequiredFields") newName += "Mandatory Labels";
        else if (policyType === "FieldMinConfidence") {
            newName += `Label Score ${selectedScoreLabel.label[1].tag_alias}_${selectedScoreLabel.score}`;
        }

        setGeneratedName(newName);
    };

    useEffect(() => {
        generateName();
    });
    return (
        <div className="relative h-fit w-full modal-grid">
            <div className="w-full h-fit flex flex-row items-center justify-center">
                <span className="text-subtitle-small">Policy Resume</span>
            </div>
            <div className={`w-full h-fit flex flex-col border-2 rounded-myb bg-slate-100 gap-2 p-5 ${newIndex >= 0 ? ' px-16 ' : ' px-2 '} justify-center`} >
                <div className="grid grid-cols-6 gap-2 w-full">
                    {generatedName && (
                        <>
                            <span className="col-span-2 font-semibold">Name:</span>
                            <span className="col-span-4">
                                {newIndex >= 0 ? (
                                    <>
                                        <input
                                            type="text"
                                            className="text-sm py-1 px-1"
                                            defaultValue={generatedName}
                                            placeholder={generatedName}
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value);
                                            }}
                                        />
                                        <br />
                                        <span className="text-xs text-slate-500">Edit the default policy name</span>

                                    </>
                                ) : (
                                    <span>{infoName ? infoName : ""}</span>
                                )}

                            </span>

                        </>

                    )}

                    <span className="col-span-2 font-semibold">Type:</span>
                    <span className="col-span-4 text-sm">
                        {policyType === "RequiredFields" ? "Mandatory Labels" : "Label Score"}
                    </span>

                    {policyType === "RequiredFields" ? (
                        <>
                            <span className="col-span-2 font-semibold">Labels:</span>
                            <div className="col-span-4 relative bg-white rounded !max-h-[175px] w-fit min-w-[200px] overflow-y-scroll flex flex-col gap-2 py-1 px-1">
                                {selectedLabels!.map((x) => (
                                    <div className="text-xs py-1 px-2 flex justify-between shadow rounded-myb" onClick={() => { }}>
                                        <span>{x[1].tag_alias}</span>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <span className="col-span-2 font-semibold">Label:</span>
                            <span className="col-span-4 text-sm">{selectedScoreLabel.label[1].tag_alias}</span>
                            <span className="col-span-2 font-semibold">Score:</span>
                            <span className="col-span-4 text-sm">{selectedScoreLabel.score}</span>
                        </>
                    )}
                    <span className="col-span-2 font-semibold">Range:</span>
                    <span className="col-span-4 text-sm">
                        {isAllPages ? "All Pages" : (fromAToB.B ? `Pages: ${fromAToB.A} - ${fromAToB.B}` : `Pages: ${fromAToB.A} - ${fromAToB.A}`)}

                    </span>

                </div>
            </div>
            <div className="h-fit flex flex-row items-center justify-center gap-2 p-2">

                {newIndex >= 0 ?
                    <Button
                        action={() => {
                            handleBackBtn();
                        }}
                        rounded
                    >
                        Go Back
                    </Button>
                    :
                    <span className="absolute top-0 right-0" onClick={() => handleBackBtn()}>
                        <svg width={40} height={40} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </span>
                }

                {generatedName && createCallback && (
                    <Button
                        action={() => {
                            createCallback(name === "" ? generatedName : name);
                        }}
                        rounded
                    >
                        Create
                    </Button>
                )}
            </div>
        </div>

    );
};
