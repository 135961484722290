import { useEffect, useState } from 'react';
import { Grid } from "react-loader-spinner";
import { useNavigate } from 'react-router';
import { logOut, refreshToken } from '../../auth/auth';

export const LoadingOverlay = ({ loadingData, navigateAfterLoad, refresh, setRefresh }) => {

    const navigate = useNavigate()
    const [seconds, setSeconds] = useState(0)
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
        const handleRefresh = async () => {
            await refreshToken().then((token) => {
                setRefresh(false)
                setLoaded(true)
                navigate(navigateAfterLoad.to, { replace: navigateAfterLoad.replace })
            }).catch((e) => {
                setRefresh(false)
                setLoaded(true)
                logOut()
                navigate('/login')
            })
        }
        if (seconds <= 0) {
            if (loadingData) {
                setSeconds(3)
            }
            else {
                if (Object.keys(navigateAfterLoad).includes('to')) {
                    if (refresh)
                        handleRefresh()

                    navigate(navigateAfterLoad.to, { replace: navigateAfterLoad.replace })

                }
                setLoaded(true)
            }
            return
        }

        const timer = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1)
        }, 1000)

        return () => clearInterval(timer)
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds])

    useEffect(() => {
        if (loadingData) {
            setLoaded(false)
            setSeconds(3)
        }
    }, [loadingData])


    return (
        <div className={!loaded ? "loader-fadeIn" : "loader-fadeOut"} >
            <div className="absolute !z-50 top-0 left-0 flex flex-col w-full h-full justify-center items-center gap-5">
                <Grid
                    visible={true}
                    height="120"
                    width="120"
                    color="#1a3066"
                    ariaLabel="grid-loading"
                    radius="12.5"
                    wrapperStyle={{}}
                    wrapperClass="grid-wrapper"
                />
            </div>
        </div>
    )
} 