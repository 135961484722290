import * as API from "apis/api";
import { RefreshSVG } from "assets/RefreshSVG";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export const ReloadButton = () => {
  const navigate = useNavigate();

  const [reloading, setReloading] = useState<"ready" | "wait" | "reloading">(
    "ready"
  );

  const text = {
    ready: "Ready",
    wait: "Try again",
    reloading: "Loading...",
  };
  const [time, setTime] = useState(0);

  const checkHealth = async () => {
    setTimeout(async () => {
      await API.getHealthCheck()
        .then(() => {
          navigate(-1);
        })
        .catch((e) => {
          setReloading("wait");
        });
    }, 1500);
  };

  useEffect(() => {
    if (time === 0) setReloading("ready");
    else
      setTimeout(() => {
        setTime((old) => old - 1000);
      }, 1000);
  }, [time]);

  useEffect(() => {
    switch (reloading) {
      case "ready":
        break;
      case "wait":
        setTime(5000);
        break;
      case "reloading":
        checkHealth();
        break;
    }
  }, [reloading]);

  return (
    <button
      className={`w-fit border-2 rounded-myb border-primary-color px-2 flex items-center gap-2 disabled:opacity-50 ${
        reloading === "wait" && "text-red-500 border-red-500"
      }`}
      onClick={() => {
        setReloading("reloading");
      }}
      disabled={reloading !== "ready"}
    >
      {text[reloading]}
      {reloading === "wait" ? (
        <>
          {"   "} {time / 1000}s
        </>
      ) : (
        <RefreshSVG height={20} width={20} />
      )}
    </button>
  );
};
