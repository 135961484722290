import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Navigate, useLocation } from "react-router-dom";

const APIurl = process.env.REACT_APP_API_FRONTEND_SERVER

export const setOrg = (org) => {
    localStorage.setItem('org', org)
}

export const fetchOrg = () => {
    let token = fetchToken()
    if (token && 'access_token' in token)
        return jwtDecode(fetchToken().access_token).org;

    return null
}

export const setToken = (token, org = "") => {
    if (org !== "")
        setOrg(org)
    localStorage.setItem('token', JSON.stringify(token))
}

export const fetchUser = () => {
    let token = fetchToken()
    if (token && 'access_token' in token)
        return jwtDecode(fetchToken().access_token).sub;
    return '';
}

export const isUserAdmin = () => {
    return jwtDecode(fetchToken().access_token).is_admin
}

export const fetchToken = () => {
    return JSON.parse(localStorage.getItem('token'))
}

export const isRefreshTokenValid = () => {
    if (fetchToken() && fetchToken().refresh_token) {
        const decodedToken = jwtDecode(fetchToken().refresh_token)
        const expirationTime = decodedToken.exp * 1000;
        const currentTime = Date.now();
        if (currentTime > expirationTime) return false;
        return true;
    }
    return false;
}
export const clearToken = () => {
    localStorage.removeItem("token")
}

export const clearOrg = () => {
    localStorage.removeItem("org")
}

export const userIsAdmin = () => {
    let token = fetchToken()
    if (token && 'access_token' in token)
        return jwtDecode(fetchToken().access_token).is_admin;
    return false;
}

export const refreshToken = async (base_url) => {
    const token = fetchToken();
    if (token === null) {
        return Promise.reject('ERROR. Token is empty. Login.')
    } else {
        await axios.post(process.env.REACT_APP_API_REFRESH_TOKEN, {}, { headers: { 'Authorization': 'Bearer ' + token['refresh_token'] } })
            .then(response => {
                token['access_token'] = response.data.access_token
                token['refresh_token'] = response.data.refresh_token
                setToken(token)
                return token
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    const newToken = fetchToken();

                    if (newToken['refresh_token'] !== token['refresh_token'])
                        return newToken

                }

            })
    }
}

export const logOut = () => {
    clearToken()
    clearOrg()
}

export async function loginCall(email, password, notification) {
    try {
        const params = new URLSearchParams();
        params.append('username', email)
        params.append('password', password)

        const { data, status } = await axios.post(`${APIurl}/auth/login`,
            params, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })

        return [data, status];
    } catch (error) {
        notification.addNotification({
            title: 'Login Error',
            message: error.response.data.detail,
            severity: 'ERROR',
            timeout: 5000
        })

        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function register(email, password, name, surname, role, phone = null, gToken) {

    const registerData = {
        "email": email,
        "password": password,
        "confirm_password": password,
        "name": name,
        "surname": surname,
        "role": role,
        "recaptcha_token": gToken
    }

    if (phone)
        registerData['phone'] = phone

    return await axios.post(`${APIurl}/auth/register`,
        JSON.stringify(registerData), {
        headers: { 'Content-Type': 'application/json' }
    })


}

export async function resetPassword(email, psw, confirm_psw, code) {

    const bodyData = JSON.stringify({
        'email': email,
        'psw': psw,
        'confirm_psw': psw,
        'code': code
    })


    const res = await axios.post(`${APIurl}/user/password/reset/confirm`,
        bodyData,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })

    return res.data;
}


export async function requestResetPassword(email) {

    const bodyData = JSON.stringify({
        'email': email,
    })


    const res = await axios.post(`${APIurl}/user/password/reset/request`,
        bodyData,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })


    return res.data;
}

export async function verifyGToken(gToken) {

    const params = new URLSearchParams({
        secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
        response: gToken

    })

    await axios.post(`https://www.google.com/recaptcha/api/siteverify`, params.toString(), {
        headers: {
            "Content-Type": 'application/x-www-form-urlencoded'
        }
    })

}

export async function verifyAccountEmail(code, email) {
    const bodyData = JSON.stringify({
        'code': code,
        'email': email
    })

    const res = await axios.post(`${APIurl}/user/account/verify`,
        bodyData,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })


    return res.data;
}

export async function sendVerificationEmail(email) {
    const bodyData = JSON.stringify({
        'email': email
    })

    const res = await axios.post(`${APIurl}/user/account/verification/send`,
        bodyData,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })


    return res.data;
}

export function RequireToken({ children, setUserInfo }) {

    let location = useLocation()
    if (fetchToken()) {
        if (!fetchOrg())
            return <Navigate to={'/org-setup'} state={{ from: location }} />
        return children
    }

    logOut()
    setUserInfo(null)
    return <Navigate to={'/login'} state={{ from: location }} />
}

export function RequireAdmin({ children }) {

    if (isUserAdmin() === false)
        return <Navigate to={'/'} />
    return children
}