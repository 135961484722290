import { Button } from "components/button/Button";
import { useEffect, useState } from "react";
import * as API from '../../apis/api';
import { fetchOrg, fetchToken } from "../../auth/auth";

export function OrgRoute() {

    const [orgInfo, setOrgInfo] = useState(null)

    useEffect(() => {
        const fetchUser = async (token) => {
            await API.getOrganization(fetchOrg()).then((res) => setOrgInfo(res))
        }
        if (orgInfo) return;
        fetchToken() && fetchUser(fetchToken())
    }, [orgInfo])

    return (
        <div className="product-bg default-page-layout">
            <div className="default-page-container">
                <div className="dashboard-head">
                    <div className="page-header">
                        <div className="text-page-title">Organization Profile</div>
                    </div>
                </div>
                <div className="ORGsettings-panel">
                    <div className=" flex flex-col gap-5">
                        <div className="input-container">
                            <label className="text-title-small">Name</label>
                            <p className="text-subtitle">Name of your organization, shown in user interfaces</p>
                            <input type='text' placeholder={orgInfo ? orgInfo.company_name : ''} value={orgInfo ? orgInfo.company_name : ''} disabled />
                        </div>
                        <div className="flex flex-row gap-24">
                            <div className="input-container">
                                <label className="text-title-small">Industry</label>
                                <p className="text-subtitle">The industry in which your organization operates</p>
                                <input type='text' placeholder={orgInfo ? orgInfo.company_type : ''} value={orgInfo ? orgInfo.company_type : ''} disabled />
                            </div>


                            <div className="input-container">
                                <label className="text-title-small">Size</label>
                                <p className="text-subtitle">The number of employees in your organization</p>
                                <input type='text' placeholder={orgInfo ? orgInfo.company_size : ''} value={orgInfo ? orgInfo.company_size : ''} disabled />
                            </div>
                        </div>

                        <div className="flex flex-row">
                            <Button className="!h-10 w-1/3" action={() => {
                                if (window.confirm('Only the changes will be saved.'))
                                    window.alert.log('TBD')
                            }}
                                disabled rounded>Save</Button>
                        </div>

                    </div>
                </div>
            </div>

        </div>)
}