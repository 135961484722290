import { useNavigate } from "react-router-dom";

import CatalogItem from "../catalogItem/catalogItem";

export default function UseCasesCard({ useCaseList, admin }) {

    const navigate = useNavigate()

    const navigateToUseCase = (usecase) => {
        navigate(`/usecase/${usecase.public_id}/documents`)
    }

    return (
        <div className="grid grid-rows-[50px_auto] px-2 py-2 bg-white !font-[Inter,_sans-serif] text-slate-300 h-full !min-h-[360px] !max-h-[75vh] border border-gray-200 rounded-lg shadow-sm">

            <div className={"flex flex-row items-center justify-between"}>
                <h3 className="text-subtitle mb-2 mx-2">Your Use Cases</h3>
            </div>

            <div className="relative overflow-y-auto grid max-[950px]:!grid-cols-1 max-[1370px]:!grid-cols-2 !grid-cols-3 w-full gap-y-10 gap-x-8 px-5 py-2">
                {useCaseList.length > 0 && useCaseList.map((useCase, index) =>
                    <CatalogItem item={useCase} fallback={navigateToUseCase} />
                )}
            </div>


        </div>
    )
}

